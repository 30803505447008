import BaseReactComponent from '../core/base-react.component';
import React, { Component,Fragment } from 'react';
import classNames from 'classnames';
import CartService from '../services/cart.service';
import I18nService from '../services/i18n.service';
import CheckoutService from '../services/checkout.service';
import CartShipppingMethodPopUp from './cart-shipping-method-popup.component';
import Cookies from "universal-cookie";

let cookies = new Cookies();
export default class ShippingMethodListComponent extends BaseReactComponent {
    constructor(props, context) {
        super(props, context);
        
        this.i18nScopeKey = 'SHIPPING_METHOD_LIST';
        this.componentName = props.business_name.trim() || _.uniqueId('shipping-method-list-');

        this.state = {
            cart: null,
            availableShippingMethods: props.availableShippingMethods,
            currentShippingMethod: props.currentShippingMethod,
            currentShipping: null,
            currentShippingAddress: null,
            isOrderProcess: true,
            isLoading: true,
            hasPlaceYourOrder: null,
            address_type: cookies.get("selectedAdressType") ? cookies.get("selectedAdressType") : "bus" ,
            selectedShipMethod:'',
            zipCode: cookies.get("selectedPostalCode") ? cookies.get("selectedPostalCode") : '',
            errors: {},
            showShippingMethods:cookies.get("selectedPostalCode") ? true : false
        }   

        this.availableShippingMethodsChangeHandler = this.availableShippingMethodsChangeHandler.bind(this)
        this.handleZipOptionChange = this.handleZipOptionChange.bind(this)
        this.handleZipCodeSubmit = this.handleZipCodeSubmit.bind(this)
        this.zipChangeHandler = this.zipChangeHandler.bind(this)
        this.getDeliveryDates = this.getDeliveryDates.bind(this)
        this.addressTypeChangeHandler = this.addressTypeChangeHandler.bind(this)
    }

    appOnInit() {
        if(super.appOnInit) super.appOnInit();
        this.i18nService = this.app.i18nService;
        this.cartService = this.app.cartService;
        this.checkoutService = this.app.getController('checkout').checkoutService;
        this.shippingMethods = this.checkoutService.availableShippingMethods;
    }

    componentDidMount() {
        this.availableShippingMethodsChangeProxyListener = this.checkoutService.addEventListener(CheckoutService.EVENT_AVAILABLE_SHIPPING_METHODS_CHANGE, this.availableShippingMethodsChangeHandler);
        this.checkoutService.addEventListener(CheckoutService.EVENT_ZIP_CHANGE, this.zipChangeHandler);
        this.cartService.addEventListener(CartService.EVENT_ADDRESSTYPE_CHANGE, this.addressTypeChangeHandler);
        this.availableShippingMethodsChangeHandler();
        this.setState({
            currentShippingMethod: this.checkoutService.currentShippingMethod,
            // zipCode: this.checkoutService.currentShippingAddress.postcode
        });
    }

    componentWillUnmount() {
        this.checkoutService.removeEventListener(CheckoutService.EVENT_AVAILABLE_SHIPPING_METHODS_CHANGE, this.availableShippingMethodsChangeProxyListener);
    }
    addressTypeChangeHandler(){ 
        let zipAndAdressPayload = {address_type: cookies.get("selectedAdressType")};
        if(this.checkoutService?.shipsToPayload?.postcode) zipAndAdressPayload.zipCode = this.checkoutService?.shipsToPayload?.postcode;
        this.setState({...zipAndAdressPayload}) 
    };

    zipChangeHandler(){
        let zipAndAdressPayload = {address_type:this.checkoutService.shipsToPayload.address_type};
        // if(this.checkoutService?.shipsToPayload?.postcode) zipAndAdressPayload.zipCode = this.checkoutService.shipsToPayload.postcode
        if(this.checkoutService?.shipsToPayload?.postcode) zipAndAdressPayload.showShippingMethods = true
        this.setState({...zipAndAdressPayload});   
    }

    handleZipCodeSubmit(event){
        event.preventDefault();
        const {address_type,zipCode} = this.state;
        var isValidZip = /^([0-9]{5})(?:[-\s]*([0-9]{4}))?$/.test(zipCode);
        if(!zipCode){
            this.setState({ errors: {zipCode:'Zip code is required.'} });
        }else if(!isValidZip){
            this.setState({ errors: {zipCode:'Invalid Zip code.'} });
        }else{
            this.setState({ errors: {} });
            this.checkoutService.shipsToPayload = {address_type,postcode:zipCode};
            this.checkoutService.getEstimatedShippingMethods();
        }
    }
    handleZipOptionChange(event){
        // this.setState({
        //    address_type: event.target.value
        // });
        this.checkoutService.shipsToPayload = {
            address_type:event.target.value
        }
        this.checkoutService.getEstimatedShippingMethods();
    }
    
    getDeliveryDates(options){
        const {
            delivery_date_range,
            expected_delivery_date,
            } = options
        function getActualDate(dateStr) {
            return dateStr.replace(/-/g, '/');
        }    
        if (delivery_date_range && typeof delivery_date_range === 'string') {
            if (delivery_date_range.includes('-') && delivery_date_range.split('-').length === 6) {
                // Split into two dates
                const dates = delivery_date_range.split('-');
                const firstDate = `${dates[0]}/${dates[1]}/${dates[2]}`;
                const secondDate = `${dates[3]}/${dates[4]}/${dates[5]}`;
                return `${firstDate} - ${secondDate}`;
            } else {
                return getActualDate(delivery_date_range)
            }
        } else if(expected_delivery_date){
         return getActualDate(expected_delivery_date)
        }
       
    }

    render(){
        const {business_name} = this.props;
        const {address_type,selectedShipMethod} = this.state;
        // const invisibleRadio = business_name === "FoodServiceDirect.com";
        if(!this.i18nService) return null;
        let list, uniqueKey,expeditedMessage = false;
        if(this.state.shippingMethods) {
            let indexShippingMethod = 0;
            
            list = this.state.shippingMethods.map( (shippingMethod,index) => {
                if(shippingMethod.carrier_title !== this.props.business_name) return null;
                
                if(shippingMethod.method_title === "Second Day Air" || shippingMethod.method_title === "Next Day Air") expeditedMessage = true;
                // if(!shippingMethod.uiID) shippingMethod.uiID = shippingMethod.method_code || _.uniqueId('shipping-method-');
                // uniqueKey = this.componentName+'-'+shippingMethod.uiID
                let defaultChecked = false;    
                if(this.cartService.selectedShippingMethod && indexShippingMethod===0){
                    const selectedShippingMethod = this.cartService.selectedShippingMethod.replace('rbmultipleshipping_','').split(':');
                    if(selectedShippingMethod.includes(`${shippingMethod.carrier_code}_${shippingMethod.method_code}`)){
                        defaultChecked = true;
                        indexShippingMethod = 1;
                    }
                }
                if(this.cartService?.selectedShippingMethod === null && indexShippingMethod===0){
                    defaultChecked = true;
                    indexShippingMethod = 1;
                }
                shippingMethod.uiID = `${shippingMethod.carrier_code}_${shippingMethod.method_code}`;
                //shippingMethod.inputField = 
                return <li key={uniqueKey} style={{cursor:"pointer"}} className={`c-shipping-method-list__item ${
                    defaultChecked ? 'c-shipping-method-list__item_selected' : ''
                }
                    `} onClick={ (e) => this.shippingMethodChangeHandler(shippingMethod, e,shippingMethod.uiID)}
                    >
                            <div className="c-shipping-method-list__item-select c-radio-button" >
                            <p className='d-flex align-items-center' style={{color:defaultChecked ? "white":"#939393"}}> 
                                <p className="circle"><span></span></p>
                                <p className="c-shipping-method-list__item-title">
                                  {shippingMethod.method_title} 
                                  {(shippingMethod?.extension_attributes?.packages?.options?.delivery_date_range ||  shippingMethod?.extension_attributes?.packages?.options?.expected_delivery_date) &&
                                  <p className="c-shipping-method-list__item-date">Expected delivery date: {this.getDeliveryDates(shippingMethod?.extension_attributes?.packages?.options)}</p>
                                  }
                                </p>
                            </p>
                            </div>
                            <span className="c-shipping-method-list__item-price">
                                {parseInt(shippingMethod.amount)==0 ? 'Free Shipping': this.i18nService.format(shippingMethod.amount, ['number', 'currency'])}
                               
                                {business_name === "FoodServiceDirect.com" && shippingMethod.method_title === "Common Carrier w/ Liftgate" &&
                                    <CartShipppingMethodPopUp/>
                                }
                            </span>
                        </li>
            });
        }
        let shipsToSection = <div className="c-shipping-method-list__shipsto_container">    
                            <form className="c-shipping-method-list__header" onSubmit={this.handleZipCodeSubmit}>
                                <div className="text-22">Ships to</div>
                                <div className={`c-shipping-method-list__item-select c-radio-button`}>
                                <input
                                    type="radio"
                                    value="bus"
                                    name="business"
                                    id="selects-1"
                                    checked={address_type === "bus"}
                                    onChange={this.handleZipOptionChange}
                                />
                                <label htmlFor="selects-1">Business</label>
                                </div>
                                <div className={`c-shipping-method-list__item-select c-radio-button`}>
                                <input
                                    type="radio"
                                    value="res"
                                    name="residentials"
                                    id="selects-2"
                                    checked={address_type === "res"}
                                    onChange={this.handleZipOptionChange}
                                />
                                <label htmlFor="selects-2">Residential</label>
                                </div>
                                <input 
                                    value={this.state.zipCode} 
                                    type="number"
                                    name="zipCode"
                                    className={`${this.state.errors.zipCode ? 'border-danger' : ''}`}
                                    onChange={(e) => e.target.value.length < 6 && this.setState({zipCode:e.target.value})} 
                                    placeholder='ZIP code' 
                                />
                                <button type="submit">calculate</button>
                            </form>
                            {this.state.errors.zipCode && <div className="text-center text-danger small">{this.state.errors.zipCode}</div>}
                        </div>
        return (
            <div className="c-shipping-method-list">
                <div className="c-shipping-method-list__container">
                
                    {this.app?.settings?.isPartnerStore ||  business_name !== "FoodServiceDirect.com" ? 
                    <div className="text-22 pl-4 pb-4 pt-2">Shipping Method</div>
                    :
                    shipsToSection
                    }
                    <ul className="c-shipping-method-list__items">
                        {(this.app?.settings?.isPartnerStore || business_name !== "FoodServiceDirect.com" || this.state.showShippingMethods) && list}
                    </ul>
                    {(!this.app?.settings?.isPartnerStore && !this.state.showShippingMethods) && <p className="text-muted p-5">Expedited Orders received before 2pm EST will be processed within 1-2 business days.</p>}
                    {expeditedMessage && this.state.showShippingMethods && <div className="c-shipping-method-list__help">Expedited Orders received before 2pm EST will be processed within 1-2 business days.</div>}
                    {/* {!expeditedMessage &&  <div className="c-shipping-method-list__help">Some items may qualify for expedited shipping, <a target="_blank" href="https://foodservicedirect.zendesk.com/hc/en-us/requests/new">contact customer service</a></div>} */}
                    
                    {this.app?.settings?.isPartnerStore && !list && <div className="c-shipping-method-list__no-data">
                        <div className="c-shipping-method-list__no-data-title">
                            {this.state.currentShippingAddress ? this.i18nService.translate('CHECKOUT.MESSAGES.SHIPPING_ADDRESS_SELECT.ERROR.TITLE') : this.i18nService.translate([this.i18nScopeKey, 'NO_DATA_TITLE'])}
                        </div>
                        <div className="c-shipping-method-list__no-data-description">
                            {this.i18nService.translate([this.i18nScopeKey, 'NO_DATA_DESCRIPTION'])}
                        </div>
                    </div>} 
                </div>
            </div>
        )
    }

    shippingMethodChangeHandler(shippingMethod,e,shippingMethodUid){
        this.setState({
            selectedShipMethod:shippingMethodUid
        })
        this.checkoutService.selectCurrentShippingMethod(shippingMethod,this.props.business_name);
    }

    availableShippingMethodsChangeHandler(){
              //  let  shippingMethods = this.checkoutService.availableShippingMethods.filter(shippingMethod=>shippingMethod.carrier_title ===props.business_name);

        this.setState({
            shippingMethods: this.checkoutService.availableShippingMethods,
        }, ()=>{
                //  this.selectFirstShippingMethod();
        });
    }

    selectFirstShippingMethod() {
        if (this.state.shippingMethods && this.state.shippingMethods.length) {
            const first = this.state.shippingMethods.find(shippingMethod => shippingMethod.carrier_title === this.props.business_name)
            const firstIndex = this.state.shippingMethods.findIndex(shippingMethod => shippingMethod.carrier_title === this.props.business_name)
            
            this.selectCurrentShippingMethod(first,firstIndex);
        }
    }

    selectCurrentShippingMethod(shippingMethod,index) {
        console.trace(shippingMethod);
        this.app.findChild(`#select-${index}`).then(element => {
            element.checked = true;
            // this.shippingMethodChangeHandler(shippingMethod);
        });
    }
}